import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Paper, TableContainer, Table, TablePagination } from '@mui/material';
import FingoTableHead from './FingoTableHead';
import FingoTableBody from './FingoTableBody';
import { sortModelToString, stringOrderByToSortModel } from './utils/selectionModelHandlers';
import DataGridNoRowsOverlay from '../dataGrids/DataGridNoRowsOverlay';

const TABLE_PAGINATION_HEIGHT = 52;

const FingoTable = (props) => {
  const {
    columns,
    rows,
    // Server props
    loading,
    noRowsMessage,
    // Selection props
    checkboxSelection,
    isRowSelectable,
    selectionModel,
    onSelectionModelChange,
    includeHeaders,
    keepNonExistentRowsSelected,
    showHeaders,
    // Sorting props
    setOrderBy,
    orderBy,
    // Pagination props
    rowCount,
    page,
    pageSize,
    onPageChange,
    onPageSizeChange,
    pagination,
    rowsPerPageOptions,
    // Collapsible props
    collapsible,
    isRowCollapsible,
    collapseComponent,
    initialExpandedRow,
    // Styling props
    density,
    screenHeight,
    customPaperBackground,
  } = props;
  const sortModel = stringOrderByToSortModel(orderBy);
  const onSortModelChange = useCallback(
    (model) => {
      const stringOrder = sortModelToString(model);
      setOrderBy(stringOrder);
    },
    [setOrderBy],
  );

  const tableColumns = includeHeaders.length !== 0
    ? columns.filter((column) => includeHeaders.includes(column.field))
    : columns;
  tableColumns.sort((a, b) => includeHeaders.indexOf(a.field) - includeHeaders.indexOf(b.field));

  if (!rows.length && !loading) {
    return (
      <DataGridNoRowsOverlay Message={noRowsMessage} />
    );
  }
  return (
    <>
      <Paper
        sx={{
          width: '100%',
          boxShadow: 'none',
          mt: 2,
          height: screenHeight - TABLE_PAGINATION_HEIGHT,
          overflow: 'scroll',
          flex: 1,
          background: customPaperBackground,
        }}
      >
        <TableContainer>
          <Table
            stickyHeader
            aria-label="Fingo table"
            size={density === 'compact' ? 'small' : 'medium'}
          >
            {showHeaders && (
              <FingoTableHead
                columns={tableColumns}
                checkboxSelection={checkboxSelection}
                isRowSelectable={isRowSelectable}
                selectionModel={selectionModel}
                onSelectionModelChange={onSelectionModelChange}
                keepNonExistentRowsSelected={keepNonExistentRowsSelected}
                collapsible={collapsible}
                sortModel={sortModel}
                onSortModelChange={onSortModelChange}
              />
            )}
            <FingoTableBody
              columns={tableColumns}
              rows={rows}
              loading={loading}
              checkboxSelection={checkboxSelection}
              isRowSelectable={isRowSelectable}
              selectionModel={selectionModel}
              onSelectionModelChange={onSelectionModelChange}
              collapsible={collapsible}
              isRowCollapsible={isRowCollapsible}
              collapseComponent={collapseComponent}
              initialExpandedRow={initialExpandedRow}
              page={page}
              rowsPerPage={pageSize}
            />
          </Table>
        </TableContainer>
      </Paper>
      {pagination
      && (
      <TablePagination
        component="div"
        count={rowCount}
        page={page}
        rowsPerPage={pageSize}
        onPageChange={(event, newPage) => onPageChange(newPage)}
        onRowsPerPageChange={(event) => onPageSizeChange(event.target.value)}
        rowsPerPageOptions={rowsPerPageOptions}
      />
      )}
    </>
  );
};

FingoTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      renderCell: PropTypes.func,
      headerName: PropTypes.string,
      sortable: PropTypes.bool,
      valueGetter: PropTypes.func,
    }),
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      selectable: PropTypes.bool,
      selected: PropTypes.bool,
      selectDisabled: PropTypes.bool,
      collapsible: PropTypes.bool,
      open: PropTypes.bool,
      collapseComponent: PropTypes.element,
    }),
  ).isRequired,
  includeHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  loading: PropTypes.bool.isRequired,
  noRowsMessage: PropTypes.func.isRequired,
  checkboxSelection: PropTypes.bool,
  isRowSelectable: PropTypes.func,
  selectionModel: PropTypes.arrayOf(PropTypes.string),
  onSelectionModelChange: PropTypes.func,
  keepNonExistentRowsSelected: PropTypes.bool,
  orderBy: PropTypes.string,
  setOrderBy: PropTypes.func,
  rowCount: PropTypes.number,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  collapsible: PropTypes.bool,
  isRowCollapsible: PropTypes.func,
  collapseComponent: PropTypes.elementType,
  density: PropTypes.string,
  screenHeight: PropTypes.number,
  pagination: PropTypes.bool,
  customPaperBackground: PropTypes.string,
  initialExpandedRow: PropTypes.string,
  showHeaders: PropTypes.bool,
};

FingoTable.defaultProps = {
  isRowSelectable: () => {},
  selectionModel: [],
  rowsPerPageOptions: [5, 10, 25],
  onSelectionModelChange: () => {},
  keepNonExistentRowsSelected: true,
  collapsible: false,
  isRowCollapsible: () => {},
  collapseComponent: () => {},
  density: 'compact',
  pagination: true,
  customPaperBackground: undefined,
  checkboxSelection: false,
  screenHeight: undefined,
  orderBy: '',
  setOrderBy: () => {},
  rowCount: null,
  page: null,
  pageSize: 15,
  onPageChange: () => {},
  onPageSizeChange: () => {},
  initialExpandedRow: null,
  showHeaders: true,
};

export default FingoTable;
