import React from 'react';
import PropTypes from 'prop-types';
import { TableCell } from '@mui/material';
import renderCellMapper from './utils/render-cell-mapper';

const FingoTableCell = ({ type, renderCell, row, valueGetter, width }) => (
  <TableCell sx={{ minWidth: width, width }}>
    {renderCell ? renderCell({ row }) : renderCellMapper[type](valueGetter({ row }))}
  </TableCell>
);

FingoTableCell.propTypes = {
  type: PropTypes.string,
  renderCell: PropTypes.func,
  valueGetter: PropTypes.func,
  width: PropTypes.number,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

FingoTableCell.defaultProps = {
  type: 'string',
  renderCell: null,
  valueGetter: () => {},
  width: null,
};

export default FingoTableCell;
