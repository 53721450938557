import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import DoneAll from '@mui/icons-material/DoneAll';
import ForwardToInbox from '@mui/icons-material/ForwardToInbox';
import Error from '@mui/icons-material/Error';
import { useMutation } from '@apollo/client';
import { useGetUser } from '../../hooks';
import { SEND_EMAIL_VERIFICATION_MAIL } from '../../graphql';
import { validEmail } from '../../helpers';

const EmailVerificationTooltip = () => {
  const currentUser = useGetUser();
  const [sendVerification] = useMutation(
    SEND_EMAIL_VERIFICATION_MAIL,
  );
  if (currentUser.isEmailVerified) {
    return (
      <Tooltip title="Verificado">
        <DoneAll color="secondary" />
      </Tooltip>
    );
  }
  if (!validEmail(currentUser.mailProvided)) {
    return (
      <Tooltip title="Este dato no está en el formato correcto">
        <Error color="error" />
      </Tooltip>
    );
  }
  return (
    <Tooltip title="Enviar verificación de correo por email">
      <IconButton onClick={sendVerification}>
        <ForwardToInbox color="primary" />
      </IconButton>
    </Tooltip>
  );
};

export default EmailVerificationTooltip;
