import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Tooltip, Link } from '@mui/material';
import { formatDayMonthYearSlash } from '../../helpers';

function traceToLabel(invoice, value, event, flatText = false) {
  const { providerRejectionReasons = [], creditNotes, loanedTo } = invoice;
  const creditNoteLabel = (labelFinish) => (creditNoteIndex) => {
    if (flatText || !creditNotes[creditNoteIndex]) {
      return `Anulada con nota de crédito por monto ${labelFinish}.`;
    }
    const realLabelFinish = creditNotes[creditNoteIndex].amountWithIva >= invoice.amountWithIva ? 'total' : labelFinish;
    return (
      <>
        Anulada con{' '}
        <Link
          component={RouterLink}
          to={{
            pathname: '/app/invoice',
            search: `?invoiceId=${creditNotes[creditNoteIndex].id}`,
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          nota de crédito #{creditNotes[creditNoteIndex].folio}
        </Link>
        {' por monto'} {realLabelFinish}.
      </>
    );
  };
  const loanedLabel = () => {
    const loanedReceptor = loanedTo ? `a ${loanedTo}` : '';
    return `Cedida ${loanedReceptor}.`;
  };
  const rejectionLabel = (sentenceEnd) => {
    if (flatText) {
      const rejectionReason = providerRejectionReasons.length ? providerRejectionReasons[0].rejectionCode?.reason : '';
      return `${sentenceEnd} - ${rejectionReason}`;
    }
    return (
      <>
        {sentenceEnd}
        {!!providerRejectionReasons.length && (
        <Tooltip title={providerRejectionReasons[0].comments}>
          <Typography sx={{ fontSize: '14px !important', fontWeight: 'light', textAlign: 'justify', pr: 4 }}>
            - {providerRejectionReasons[0].rejectionCode?.reason}.
          </Typography>
        </Tooltip>
        )}
      </>
    );
  };
  const paymentLabel = (status, paymentDate, bankName) => (paymentDate ? `${status} el ${formatDayMonthYearSlash(paymentDate)} ${bankName}` : status);
  const { paymentDate = null, status: { status = null } = {}, bank = null } = event || {};
  const bankName = bank ? `a través de ${bank.name}` : '';
  return {
    ERM: { label: 'Se da acuse de recibo.', color: 'success.main' },
    ACD: { label: 'Se acepta contenido del documento.', color: 'success.main' },
    ENC: { label: creditNoteLabel('parcial'), color: 'error.main' },
    NCA: { label: creditNoteLabel('total'), color: 'error.main' },
    RCD: { label: rejectionLabel('Con reclamos al contenido.'), color: 'error.main' },
    RFT: { label: rejectionLabel('Rechazada por falta total de servicios.'), color: 'error.main' },
    RFP: { label: rejectionLabel('Rechazada por falta parcial de servicios.'), color: 'error.main' },
    CED: { label: loanedLabel(), color: 'warning.main' },
    PAG: { label: 'Emitida al contado.', color: 'success.main' },
    6: { label: paymentLabel(status, paymentDate, bankName), isProviderInfo: true, color: 'success.main' },
    7: { label: paymentLabel(status, paymentDate, bankName), isProviderInfo: true, color: 'success.main' },

  }[value] ?? { label: status, isProviderInfo: true };
}

export default traceToLabel;
