/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

export const TableTermSection = ({ id, text }) => {
  const validJsonString = text.replace(/([a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\s]+)/g, '"$1"');
  const tableData = JSON.parse(validJsonString);
  return (
    <TableContainer component={Paper} sx={{ mt: 2, mb: 2 }} key={id}>
      <Table sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
        <TableHead>
          <TableRow>
            {tableData[0].map((col) => (
              <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{col}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.slice(1).map((row) => (
            <TableRow>
              {row.map((cell) => (
                <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{cell}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TableTermSection.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  text: PropTypes.string.isRequired,
};
