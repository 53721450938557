import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AlertOneDialog from '../dialogs/AlertOneDialog';
import { SiiLogo } from '../../assets';

const useStyles = makeStyles({
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  image: {
    height: '30px',
  },
});

const MoreSiiCredentialsInfo = ({ open, onClose, isOrdering }) => {
  const classes = useStyles();
  let moreInfoOne = '';
  if (isOrdering) {
    moreInfoOne = `
    A través de la autorización de acceso a tus credenciales del SII, 
    Fingo podrá entregar una oferta para tu orden de compra, de forma automática, 
    a través de la utilización de tecnología avanzada, 
    cuyo único objeto es prestar un servicio confiable y de calidad a sus clientes.
    `;
  } else {
    moreInfoOne = `
    A través de la autorización de acceso a tus credenciales del SII, 
    Fingo podrá entregar una pre oferta para tus facturas, de forma automática, 
    a través de la utilización de tecnología avanzada, 
    cuyo único objeto es prestar un servicio confiable y de calidad a sus clientes.
    `;
  }
  const moreInfoTwo = `
  Fingo, a través del robot Jarvis, accederá a la información de tu empresa registrada en el SII, 
  tales como facturas emitidas, carpeta tributaria, 
  certificados de Tesorería General de la Republica, entre otra información disponible en dicho sistema web.
  `;
  const moreInfoThree = `
  Fingo se caracteriza por el correcto y adecuado manejo y utilización de la información de sus clientes, 
  a través de técnicas avanzadas de criptografía y seguridad.
  `;
  const title = `
    Más Información
  `;
  const titleComponent = (
    <div className={classes.title}>
      <Typography variant="h3" color="textPrimary" align="left">
        {title}
      </Typography>
      <img src={SiiLogo} alt="" className={classes.image} />
    </div>
  );
  const messageComponent = (
    <>
      <Typography variant="body1" align="justify" color="textPrimary" paragraph>
        {moreInfoOne}
      </Typography>
      <Typography variant="body1" align="justify" color="textPrimary" paragraph>
        {moreInfoTwo}
      </Typography>
      <Typography variant="body1" align="justify" color="textPrimary" paragraph>
        {moreInfoThree}
      </Typography>
    </>
  );
  return (
    <AlertOneDialog
      open={open}
      onClose={onClose}
      title={titleComponent}
      message={messageComponent}
      showButton
    />
  );
};

MoreSiiCredentialsInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isOrdering: PropTypes.bool.isRequired,
};

export default MoreSiiCredentialsInfo;
